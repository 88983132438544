<template>
    <div class="modal fade show" id="goFullscreenModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_question") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t("common_goInFullscreen") }}</p>
                </div>
                <div class="modal-footer">
                    <button id="goFullscreenModalNoButton" type="button" @click="cancelGoToFullScreenButton" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_no") }}</button>
                    <button id="goFullscreenModalYesButton" type="button" @click="goToFullScreenButton" data-dismiss="modal" class="btn btn-brand">{{ $t("common_yes") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToFullScreenButton() {
            console.log("Component(goFullscreenModal)::goToFullScreenButton() - called");
            $(".leaflet-control-zoom-fullscreen.fullscreen-icon")[0].click();
            setTimeout(() => {
                document.getElementById("map").scrollIntoView();
            }, 50);
        },
        cancelGoToFullScreenButton() {
            setTimeout(() => {
                document.getElementById("map").scrollIntoView();
            }, 50);
        }
    }
};
</script>

<style scoped></style>
